<template>
  <router-view />
</template>
<script>
import ApiService from "@/core/services/ApiService";
export default {
  name: "drawPage",
  mounted() {
    // Redirect to 1 page if there is no page number
    this.redirectList();
  },
  updated() {
    //Redirect to 1 page if there is no page number
    this.redirectList();
  },
  methods: {
    //Redirect to 1 page if there is no page number
    redirectList() {
      if (this.$route.name == "draw") {
        ApiService.post(
          process.env.VUE_APP_API_URL +
            "&mode=pub&tab=tourframe&action=gettourinfo",
          {}
        ).then((response) => {
          this.$router.replace({name: 'tourDrawInfo', params: { event_id: response.data["event_id"], tour_id: response.data["tour_id"], divi: response.data["divi"] }});
        });
      }
    },
  },
};
</script>
